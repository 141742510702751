import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import i18n from './libs/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//FontAwesome
library.add(fas)
Vue.component('fa', FontAwesomeIcon)

import { localize } from 'vee-validate'
localize('sl')

//dayjs
import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/utc'))
Vue.prototype.$dayjs = dayjs

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$printError = function(error) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: i18n.t('general.error'),
      icon: 'DangerOctagonIcon',
      text: error,
      variant: 'danger'
    }
  })
}

Vue.prototype.$printWarning = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: i18n.t('general.warning'),
      icon: 'AlertTriangleIcon',
      text: message,
      variant: 'warning'
    }
  })
}

Vue.prototype.$printSuccess = function(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: i18n.t('general.success'),
      icon: 'CheckCircleIcon',
      text: message,
      variant: 'success'
    }
  })
}

Vue.prototype.$confirmDialog = function(message, yesButtonMessage = i18n.t('general.yes'), noButtonMessage = i18n.t('general.no')) {
  return this.$bvModal.msgBoxConfirm(message, {
    size: 'sm',
    okVariant: 'danger',
    okTitle: yesButtonMessage,
    cancelTitle: noButtonMessage,
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true
  })
}

async function init() {
  const store = (await import('@/store')).default
  const App = (await import('@/App.vue')).default
  await import('@/libs/axios.js')


  try {
    await Vue.prototype.$http.get('/api/reseller/v1/ping')

    if (localStorage.userData) {
      await store.dispatch('user/updateUserData', JSON.parse(localStorage.userData))
      await store.dispatch('user/setLoginStatus', true)

      const response = await Vue.prototype.$http.get('/api/reseller/v1/client/has_clients')
      const hasClients = response.data ?? false

      if (!hasClients) await store.dispatch('user/setUserNoClients')
    }
  } catch (err) {
    await store.dispatch('user/setLoginStatus', false)
  }

  const router = (await import('@/router')).default
  await import('@/libs/permissions')
  await import('@/libs/sentry')

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

}

init()